<template>
  <div>Loading ...</div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
// import store from "@/store";
// import router from "@/router";

export default defineComponent({
  name: "SignInRedirect",
  setup() {
    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      let redirectUrl = router.currentRoute.value.query.redirect || null;
      if(redirectUrl) {
        // redirectUrl = Buffer.from(redirectUrl.toString(), 'base64').toString();
        redirectUrl = atob(redirectUrl.toString());
        store.commit(Mutations.SET_AUTH_REDIRECT_URL, redirectUrl);

        if(store.getters.isUserAuthenticated) {
          store.dispatch(Actions.REDIRECT_ON_AUTH);
          return;
        }
      }

      router.push({name: 'sign-in'});
    });

    return {};
  }
});
</script>
